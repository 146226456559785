<template>
  <v-container v-if="!orderService">
    <FullScreenDialog
      v-model="dialog"
      without-padding
      title="Famiglie"
      :route-father="routeFather"
    >
      <template v-slot:header-actions
        v-if="!!currentUser && !!currentUser.system && !isLicenseBlocked"
      >
        <v-btn text link @click="goToNew">
          <v-icon class="mr-2">mdi-plus</v-icon> Nuovo
        </v-btn>
      </template>
      <template v-slot:content>
        <div class="d-flex justify-center align-center mb-2" style="width: 100%">
          <AdvancedFilter
            style="width:99%; height: 100%"
            v-model="filtersValue"
            :filters="filterTypes"
            :external-filter="externalFilter"
            @apply-filters="applyFilters"
          >
            <template v-slot:chip-custom-name="{filter}">
              Nome: {{ filter.value }}
            </template>

            <template v-slot:chip-custom-color="{filter}">
              <div
                class="d-flex justify-center align-center"
                style="gap: 5px"
              >
                <span>Colore: </span>
                <v-avatar
                  :color="!!filter.value ? filter.value.hex : 'transparent'"
                  class="d-inline-block"
                  size="20"
                ></v-avatar>
              </div>
            </template>

            <template v-slot:custom-color="{filter}">
              <v-row>
                <v-col 
                  class="d-flex justify-center"
                  cols="12"
                >
                  <v-color-picker
                    :width="!!$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '300px' : '500px'"
                    v-model="filter.value"
                    label="Colore"
                    show-swatches
                  ></v-color-picker>
                </v-col>
              </v-row>
            </template>

          </AdvancedFilter>
        </div>
        <div 
          :style="{
            'height': $vuetify.breakpoint.smAndDown ? '81%' : '86%', 
            'overflow-y': 'hidden'
          }" 
          ref="dataTableWrapper"
        >
          <TypeDataTable
            v-model="selected"
            loading-text="Caricamento Famiglie ..."
            :headers="headers"
            :loading="loading"
            :items="filteredServiceTypes"
            :page.sync="page"
            :row-per-page.sync="rowPerPage"
            :total-pages.sync="totalPages"
            :height="dataTableHeight"
            :show-select="false"
            :show-actions="!!currentUser && !!currentUser.system && !isLicenseBlocked"
            :show-order="true"
            @edit="handleEdit"
            @editRow="handleEditRow"
            @delete="handleDelete"
            @dblclick:row="handleEditDoubleClick"
          ></TypeDataTable>
        </div>
      </template>
    </FullScreenDialog >
  </v-container>

  <v-container v-else>
    <StandardDialog 
      v-model="orderService" 
      :dialog-height="null" 
      title="Ordina servizi" 
      close-button-text="Chiudi"
    >
      <OrderServiceForm
      :id="id"
      :bus="bus">

      </OrderServiceForm>
      <template v-slot:footer-actions>
          <v-btn color="error" text @click="closeOrderService"
            >Chiudi</v-btn
          >
          <v-btn
            color="default"
            text
            @click="changeOrder"
          >Conferma</v-btn
        >
        </template>
    </StandardDialog>
  </v-container>
</template>

<script>
import Vue from 'vue'
import FullScreenDialog from '@/components/common/FullScreenDialog.vue'
import StandardMenu from '@/components/common/StandardMenu.vue'
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import serviceTypesService from '@/services/serviceTypes/serviceTypes.service.js'
import StandardDialog from "@/components/common/StandardDialog.vue";
import OrderServiceForm from '@/components/areas/registries/serviceTypes/OrderServiceForm.vue'

export default {
  name: "ServiceTypesRegistryList",
  components: {
    FullScreenDialog,
    TypeDataTable,
    StandardMenu,
    AdvancedFilter,
    StandardDialog,
    OrderServiceForm
  },
  data: function() {
    let props = this.$router.resolve({name: 'Registries'});

    return {
      dialog: true,
      loadingItems: false,
      loadingHeaders: false,
      routeFather: props.href,
      selected: undefined,
      dataTableHeightValue: 400,
      serviceTypes: [],
      headers: [],
      page: 1,
      rowPerPage: 100,
      totalPages: 0,
      resizeListener: undefined,
      filterTypes: [
        { type: 'string', operator: 'like', field: 'description', name: 'Descrizione', label: 'Descrizione', color: "", value: undefined, },
        { type: 'number', operator: 'equal', field: 'highFidelityDays', name: 'Limite Giorni Alta Fidelizzazione', label: 'Limite Giorni Alta Fidelizzazione', color: "", value: undefined },
        { type: 'number', operator: 'equal', field: 'mediumFidelityDays', name: 'Limite Giorni Bassa Fidelizzazione', label: 'Limite Giorni Bassa Fidelizzazione', color: "", value: undefined },
        { type: 'custom', operator: 'custom', field: 'color', name: 'Colore', color: "", value: undefined, icon: 'mdi-format-color-fill'},
      ],
      externalFilter: { type: 'custom', operator: 'custom', field: 'name', name: 'Nome', label: 'Nome', color: "", value: undefined },
      filtersValue: [],
      currentUser: {},
      isLicenseBlocked: undefined,
      orderService: false,
      id: undefined
    }
  },
  props: {
    bus: {
      type: Object,
      default: function() {
        return new Vue()
      }
    },
    filters: {
      type: Array,
      default: () => []
    }
  },
  mounted: function() {
    this.fetchHeaders()
    this.filtersValue = this.filters
    this.fetchServiceTypes()

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60
    this.resizeListener = function() {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60
    }
    this.resizeListener = this.resizeListener.bind(this)
    window.addEventListener('resize', this.resizeListener)
    
    if(!this.bus)
      this.bus = new Vue()
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    fetchServiceTypes() {
      let filters = [...this.filtersValue]
      this.loadingItems = true
      serviceTypesService.list(this.page, this.rowPerPage, filters).then((results) => {
        if(this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage
        }

        if(this.page != results.page) {
          this.page = results.page
        }

        this.totalPages = results.totalPages
        if(this.totalPages < this.page) {
          this.page = this.totalPages
        }

        this.serviceTypes = results.rows
        this.loadingItems = false
      })
    },
    fetchHeaders() {
      this.loadingHeaders = true
      serviceTypesService.fields().then((headers) => {
        this.headers = headers
        this.loadingHeaders = false
      })
    },
    handleEdit(serviceType) {
      this.$router.push({
        name: 'ServiceTypesRegistryEditForm', 
        params: {
          id: serviceType.id,
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'ServiceTypesRegistryList'}).href,
          pathName: "ServiceTypesRegistryList"
        }
      })
    },
    handleEditRow(serviceType) {
      this.id = serviceType.id
      this.orderService = true
    },
    closeOrderService(){
      this.orderService = false
      this.bus.$emit('closeOrder')
    },
    changeOrder(){
      this.orderService = false
      this.bus.$emit('changeOrder')
    },
    handleEditDoubleClick(row, {item: serviceType}) {
      this.$router.push({
        name: 'ServiceTypesRegistryEditForm', 
        params: {
          id: serviceType.id,
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'ServiceTypesRegistryList'}).href,
          pathName: "ServiceTypesRegistryList"
        }
      })
    },
    handleDelete(serviceType) {
      let confirmed = confirm('Sei sicuro di voler eliminare ' + serviceType.name + ' ?')
      if(confirmed) {
        if (!!serviceType.services && !!serviceType.services.length > 0)
          this.$delegates.snackbar('Dei servizi sono associati a questa famiglia, impossibile eliminarla')
        else {
          this.loadingItems = true
          serviceTypesService.archive(serviceType).then(() => {
            this.fetchServiceTypes()
            this.loadingItems = false
          })
        }
      }
    },
    goToNew() {
      this.$router.push({
        name: 'ServiceTypesRegistryNewForm',
        params: {
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'ServiceTypesRegistryList'}).href
        }
      })
    },
    applyFilters() {
      this.fetchServiceTypes();
    },
  },
  computed: {
    filteredServiceTypes() {
      return this.serviceTypes
    },
    loading() {
      return this.loadingItems || this.loadingHeaders
    },
    dataTableHeight() {
      return this.dataTableHeightValue + 'px'
    }
  },
  watch: {
    page() {
      this.fetchServiceTypes(this.currentFilters)
    },
    rowPerPage() {
      this.fetchServiceTypes(this.currentFilters)
    },
  },
  currentUser: {
    bind: 'currentUser'
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  },
}
</script>